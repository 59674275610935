// Selection des articles, vérifier type models Express 20/02/2024 //

import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, Link, Redirect, withRouter, useHistory, Navigate } from 'react-router-dom';
import './index.css';
import api from './utils/api';
import { register, authorize, checkToken } from './utils/auth';
import ProtectedRoute from './components/protected-route/ProtectedRoute';
import Header from './components/Header/Header';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import Tooltip from './components/Tooltip/Tooltip';
import Footer from './components/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faCircle,
  faCheckCircle,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { CurrentUserContext } from './contexts/CurrentUserContext';

const App = () => {
  const [items, setItems] = useState([]);
  // const [selectedItems, setSelectedItems] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [currentUser, setCurrentUser] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  // const [isItemListOpen, setIsItemListOpen] = useState(false);
  const [isTooltipPopupOpen, setIsTooltipPopupOpen] = useState(false);
  const [hasError, setHasError] = useState(false);

  const history = useHistory();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (token && loggedIn) {
  //     api.getAppInfo(token)
  //     .then((res) => {
  //       setItems(res.data)
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       })
  //   }
  // }, [loggedIn])
  

  const handleTokenCheck = () => {
    if (token) {
      checkToken(token)
        .then((res) => {
          if (!res) {
            return res.status(400).send({
              message: "Token is missing or expired",
            });
          }
          setLoggedIn(true);
          history.push('/');
        })
        .catch((err) => console.log(err))
    } else {
      setLoggedIn(false);
    }
  }

  // Last logged-in auto connect //
  useEffect(() => {
    if (token) {
        checkToken(token)
        .then((res) => {
          setLoggedIn(true);
          history.push('/');
        })
        .catch((err) => console.log(err));
    }
  }, [history, token]);
  
  // Display name //
  useEffect(() => {
    api
      .getCurrentUser(token)
      .then((res) => { 
        setCurrentUser(res.user);
      })
        .catch((err) => {
          console.log(err);
      })    
  }, [token]);
  
  // Chargement articles //
  useEffect(() => {
    if (token && loggedIn) {
      api
        .getItems(token)
        .then((items) => {
          // Filter items based on the current user's id
          const userItems = items.filter((item) => item.userId === currentUser.id);
          setItems(userItems);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [loggedIn, currentUser, token]);
  
  useEffect(() => {
    handleTokenCheck(token)
  }, []);
 
  // Add Item //
  const handleAddButtonClick = () => {
    const newItem = {
      item: inputValue,
      // quantity: 1,
      // isSelected: false,      
    };
    const newItems = [...items, newItem];
    api.setData(newItem, token) 
      .then((data) => {
        setItems(newItems);
        setInputValue('');        
        // calculateTotal();
      })
      .catch(err => console.log(err));
  };

 // Remonve item //
 function handleItemDelete(itemId) {
  if (!itemId) {
    console.error('Invalid itemId:', itemId);
    return;
  }
  api.removeItem(itemId, token)
    .then(() => {
      // Filter out the deleted item and update state
      setItems(prevItems => prevItems.filter(data => data._id !== itemId));
    })
    .catch((err) => console.log('Error deleting item:',err));
  };
  
  // Selected //
  function toggleComplete(id) {
    const isSelected = items.some(item => item._id === id && item.sel.includes(currentUser._id));    
    if (isSelected) {
      // If the item is selected, unselect it
      api.unselectedItem(id, token)
        .then(() => {
          setItems(prevItems => prevItems.map(item => {
            if (item._id === id) {
              return { ...item, sel: item.sel.filter(userId => userId !== currentUser._id) };
            }
            return item;
          }));
        })
        .catch(err => console.error('Error unselecting item:', err));
    } else {
      // If the item is not selected, select it
      api.selectedItem(id, token)
        .then(() => {
          setItems(prevItems => prevItems.map(item => {
            if (item._id === id) {
              return { ...item, sel: [...item.sel, currentUser._id] };
            }
            return item;
          }));
        })
        .catch(err => console.error('Error selecting item:', err));
    }
  }
  
  // JSX Button and circle icon
  function itemSelectButtonClassName(id) {
    const isLiked = (id.sel ?? []).some((user) => user === currentUser._id);
    return `item-name ${isLiked ? 'completed' : ''}`;
  };

  function circleChecked(id) {
      const isChecked = (id.sel ?? []).some((user) => user === currentUser._id);   
      return isChecked ? faCheckCircle : faCircle;
  };

  // Signin button //
  const handleSignInClick = () => {
    setHasError(false);
    setIsSignInOpen(true);
    setIsSignUpOpen(false);
    setIsTooltipPopupOpen(false);
  };

  function handleItemSelected(index) {
    toggleComplete(index, items[index]);
  }

  // Signup button//
  const handleSignUpClick = () => {
    setHasError(false);
    setIsSignUpOpen(true);
    setIsSignInOpen(false);
    setIsTooltipPopupOpen(false);
  };  
 
  const handleLogOut = () => {
    setLoggedIn(false);
    localStorage.removeItem('token');
    setCurrentUser({})
  };

  const handleLogin = () => {
    setHasError(false);
    setLoggedIn(true);
    setIsSignInOpen(false);
    history.push("/");
  };

  const handleQuantityIncrease = index => {
    const newItems = [...items];
    newItems[index].quantity++;
    setItems(newItems);
    calculateTotal();
  };

  const handleQuantityDecrease = index => {
    const newItems = [...items];
    newItems[index].quantity--;
    setItems(newItems);
    calculateTotal();
  };

  const calculateTotal = () => {
    const totalItemCount = items.reduce((total, item) => {
      return total + item.quantity;
    }, 0);

    setTotalItemCount(totalItemCount);
  };

  const [totalItemCount, setTotalItemCount] = useState();
 
  const handleRegister = () => {
    setHasError(false);
    setIsSignUpOpen(false);
    setIsTooltipPopupOpen(true);
  };  

  // Login //
  const handleLoginSubmit = (email, password) => {
    authorize(email, password)
      .then(res => {
        if (res.token) {
          setCurrentUser(res.data)
          //localStorage.setItem('token', res.token);
          setToken(res.token);
          handleLogin();
          history.push('/');
        }
      })
      .catch(err => {
        console.error(`Incorrect email or password: ${err.message}`);
        setHasError(true);
      });
  };

  // Signup //
  function handleRegisterSubmit(email, password, name) {
    register(email, password, name )
      .then((res) => {
        if (res) {
          setIsRegistered(true);
          handleRegister();
          // history.push('/');
        } else {
          setIsRegistered(false);
          setHasError(true);
        }
      })
      .catch(err => {
        console.error(`This email is unavailable: ${err.message}`);
        setHasError(true);
      });
  };

  useEffect(() => {
    const addByEnter = (e) => {
      if (e.key === 'Enter') {
        handleAddButtonClick()
        console.log(e.key);
      }
    };
    const handleKeyDown = (event) => {
      addByEnter(event);
    };
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleAddButtonClick])
  
  useEffect(() => {
    const closeByEscape = (e) => {
      if (e.key === 'Escape') {
        closeAllPopups();
      }
    }
    document.addEventListener('keydown', closeByEscape)
    return () => document.removeEventListener('keydown', closeByEscape)
  }, []) 

  const closeAllPopups = () => {
    setIsSignInOpen(false);
    setIsSignUpOpen(false);
    setIsTooltipPopupOpen(false);
  };

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <div className="app-background">
        <Header
          // signup={<Redirect to="/signup" />} 
          loggedIn={loggedIn}
          currentUser={currentUser}
          setLoggedIn={setLoggedIn}
          onLogOut={handleLogOut}
          // setIsItemListOpen={setIsItemListOpen}
          onSignInClick={handleSignInClick}
        />
        <Switch>
          <ProtectedRoute path="/" loggedIn={loggedIn}> 
            {/* {isItemListOpen} */}
            <div className="main-container">
              <div className="add-item-box">
                <input
                  value={inputValue}
                  onChange={event => setInputValue(event.target.value)}
                  className="add-item-input"
                  placeholder="Add an item..."
                />
                <FontAwesomeIcon icon={faPlus} onClick={handleAddButtonClick} />
            </div>

            <ul className="item-list">
              {items.map((data, index, item) => (
                  // <li key={data._id || index} className='item-container'>
                  <li key={data._id} className='item-container'>                    
                    {/* {console.log("Item ID:", data._id)} */}
                    <div                    
                      className={itemSelectButtonClassName(data)}  
                      // className='item-name'                  
                      onClick={() => data._id && toggleComplete(data._id)}
                      >                     
                      
                      {/* <FontAwesomeIcon icon={faCircle}/> */}
                      <FontAwesomeIcon icon={circleChecked(data)}/>
                      <span>{data.item}</span>                        
                      {/* <div className="remove-button"></div> */}
                      {/* {data.isSelected ? (
                        <>
                          <FontAwesomeIcon icon={faCheckCircle} />
                          <span className="completed">{data.item}</span>
                        </>
                      ) : ( */}
                      {/* <>
                        <FontAwesomeIcon icon={faCircle} />
                        <span>{data.item}</span>
                      </> */}
                      {/* )} */}
                    </div>
                    {/* <div className="quantity">
                      <button
                        onClick={() => handleQuantityDecrease(index)}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </button>
                      <span> {items.quantity} </span>
                      <button
                        onClick={() => handleQuantityIncrease(index)}
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </button>
                    </div>                     */}
                    <div className="remove-button" onClick={() => data._id && handleItemDelete(data._id)}></div>
                  </li>
              ))}
            </ul>
            {/* <div className="total">Total: {totalItemCount}</div> */}
            </div>
          </ProtectedRoute>
          </Switch>
          
          {/* <Route exact path="/signin"> */}
            <Login              
              isOpen={isSignInOpen}
              onClose={closeAllPopups}
              onSignUpClick={handleSignUpClick}
              onLogInSubmit={handleLoginSubmit}
              hasError={hasError}
            />
            {/* {loggedIn ? <Redirect to="/items" /> : <Redirect to="/signup" />} */}
          {/* </Route> */}
            {/* <Route exact path="/signup">     */}
            <Register
              isOpen={isSignUpOpen}
              onClose={closeAllPopups}
              onSignInClick={handleSignInClick}
              onRegisterSubmit={handleRegisterSubmit}
              hasError={hasError}
            />
          {/* </Route> */}         
          <Route path="*">
            {loggedIn ? <Redirect to="/items" /> : <Redirect to="/signin" />}
          </Route>
        <Tooltip
          isOpen={isTooltipPopupOpen}
          onClose={closeAllPopups}
          onSignInClick={handleSignInClick}
          onSignUpClick={handleSignUpClick}
          isRegistered={isRegistered}
        />
        <Footer />
      </div>
    </CurrentUserContext.Provider>
  );
};

export default withRouter(App);

/*import React, { useState, useEffect, useContext, Component } from 'react';
import { Routes, Route, Navigate , useNavigate } from 'react-router-dom';
import './index.css';
import api from './utils/api';
import { register, authorize, checkToken } from './utils/auth';
import Header from './components/Header/Header';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import Tooltip from './components/Tooltip/Tooltip';
import ProtectedRoute from './components/protected-route/ProtectedRoute';
import Footer from './components/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faCircle, faCheckCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { CurrentUserContext } from "./contexts/CurrentUserContext";

const App = ({ item, data}) => {
	// HINT: each "item" in our list names a name, a boolean to tell if its been completed, and a quantity //
	const [items, setItems] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const itemContext = useContext(CurrentUserContext);
  const navigate = useNavigate();
  // const location = useLocation().pathname.substring(1);
  const [currentUser, setCurrentUser] = useState({});
  const [token, setToken] = useState(localStorage.getItem('token'));
  // const [cards, setCards] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  // const [searchKeyword, setSearchKeyword] = useState([]);
  // const [isCardListOpen, setIsCardListOpen] = useState(false);
  // const [onSavedArticlesPage, setOnSavedArticlesPage] = useState(false);
  const [isTooltipPopupOpen, setIsTooltipPopupOpen] = useState(false);
  const [hasResults, setHasResults] = useState(false);
  
  const [hasError, setHasError] = useState(false);
  const [savedArticles, setSavedArticles] = useState([]);
  const [displayedCards, setDisplayedCards] = useState([]);
  const [savedCardsArray, setSavedCardsArray] = useState([]);

  const newItem = {
    itemName: inputValue,
    // _id: items,
    quantity: 1,
    isSelected: false,
  };
  const newItems = [...items, newItem];

  // const isOwn = item.owner._id === CurrentUserContext._id;

  // const newItem = {
  //   itemName: inputValue,
  //   quantity: 1,
  //   isSelected: false,
  // };

  // const newItems = [...items, newItem];

  // Render content //



  useEffect(() => {
      api.getAppInfo()
        .then(([res]) => {
          setItems(res);
        })
        .catch(err => {
          console.log(err)
        })
  }, []);

 function handleSignInClick() {
    setHasError(false);
    setIsSignInOpen(true);
    setIsSignUpOpen(false);
    setIsTooltipPopupOpen(false);
  }

	// Add Item //
  function handleAddButtonClick() {
    api.setData({
      item,
    })
      .then(() => {
        setItems(newItems);
        setInputValue('');
        calculateTotal();
      })
      .catch(err => console.log(err))
  }

  function handleLogOut() {
    setLoggedIn(false);
    localStorage.removeItem('token');
    navigate.push('/signin');
  }

  function handleLogin() {
    setHasError(false);
    setLoggedIn(true);
    setIsSignInOpen(false);
  }

  // items.map((item) =>
  //   item.isSelected === item.itemName ? faCheckCircle : faCircle)

	// const handleAddButtonClick = () => {
	// 	const newItem = {
  //    itemName: inputValue,
	// 		quantity: 1,
	// 		isSelected: false,
	// 	};
	// 	const newItems = [...items, newItem];
	// 	setItems(newItems);
	// 	setInputValue('');
	// 	calculateTotal();
	// };

	// Select - Unselect //
  const toggleComplete = (index) => {
    const newItems = [...items];
    newItems[index].isSelected = !newItems[index].isSelected;
    setItems(newItems);
  };

	// Increase //
	const handleQuantityIncrease = (index) => {
		const newItems = [...items];
		newItems[index].quantity++;
		setItems(newItems);
		calculateTotal();
	};

	// Decrease //
	const handleQuantityDecrease = (index) => {
		const newItems = [...items];
		newItems[index].quantity--;
		setItems(newItems);
		calculateTotal();
	};

	// Total //
  const calculateTotal = () => {
    const totalItemCount = items.reduce((total, item) => {
      return total + item.quantity;
    }, 0);

    setTotalItemCount(totalItemCount);
  };

	const [totalItemCount, setTotalItemCount] = useState();

  function handleSignUpClick() {
    setHasError(false);
    setIsSignUpOpen(true);
    setIsSignInOpen(false);
  }

  function handleRegister() {
    setHasError(false);
    setIsSignUpOpen(false);
    setIsTooltipPopupOpen(true);
  }

  function handleLoginSubmit(email, password) {    
    authorize(email, password)
      .then((data) => {
        if (data.token) {
          localStorage.setItem('token', data.token);
          setToken(data.token);
          handleLogin();
          // navigate.push('/');
        }
      })
      .catch((err) => {
        console.log(`Incorrect email or password: ${err.message}`);
        setHasError(true);
      });
  }

  function handleRegisterSubmit(email, password, name) {    
    register(email, password, name)
      .then((res) => {
        if (res) {
          setIsRegistered(true);
          handleRegister();
        } else {
          setIsRegistered(false);
          setHasError(true);
        }
      })
      .catch((err) => {
        console.log(`This email is unavailable: ${err.message}`);
        setHasError(true);
      });
  }

  function closeAllPopups() {
    setIsSignInOpen(false);
    setIsSignUpOpen(false);
    setIsTooltipPopupOpen(false);
  }

	return (
    <CurrentUserContext.Provider value={currentUser}>
      <div className='app-background'>
        <Header
          signup={<Navigate  to="/signup"/>} 
          loggedIn={loggedIn}
          currentUser={currentUser}
          setLoggedIn={setLoggedIn}
          onSignInClick={handleSignInClick}
          // setIsCardListOpen={setIsCardListOpen}
          // setSearchKeyword={setSearchKeyword}
          // onSavedArticlesPage={onSavedArticlesPage}
          onLogOut={handleLogOut}
        />
        <div className='main-container'>
                
            <div className='add-item-box'>
              <input value={inputValue} onChange={(event) => setInputValue(event.target.value)} className='add-item-input' placeholder='Add an item...' />
              <FontAwesomeIcon icon={faPlus} onClick={() => handleAddButtonClick(newItems)} />
            </div>          
            
            <ul className='item-list'>
              {items.map((item, index) => (
                <li key={item._id} className='item-container'>
                  <div className='item-name' value={data} name="data"  onClick={() => toggleComplete(index)}>
                    {item.isSelected ? (
                      <>
                        <FontAwesomeIcon icon={faCheckCircle}  />
                        <span className='completed'>{item.itemName}</span>
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faCircle} />
                        <span>{item.itemName}</span>
                      </>
                    )}
                  </div>
                  <div className='quantity'>
                    <button>
                      <FontAwesomeIcon icon={faChevronLeft} onClick={() => handleQuantityDecrease(index)} />
                    </button>
                    <span> {item.quantity} </span>
                    <button>
                      <FontAwesomeIcon icon={faChevronRight} onClick={() => handleQuantityIncrease(index)} />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            <div className='total' >Total: {totalItemCount}</div>
            
          
        </div>
        <Routes>
        <Route exact path="/signin">
          <Login
            isOpen={isSignInOpen}
            onClose={closeAllPopups}
            onSignUpClick={handleSignUpClick}
            onLogInSubmit={handleLoginSubmit}
            hasError={hasError}
          />
        </Route>
        <Route exact path="/signup">
          <Register
            isOpen={isSignUpOpen}
            onClose={closeAllPopups}
            onSignInClick={handleSignInClick}
            onRegisterSubmit={handleRegisterSubmit}
            hasError={hasError}
          />
        </Route>
        </Routes>
        <Tooltip
          isOpen={isTooltipPopupOpen}
          onClose={closeAllPopups}
          onSignInClick={handleSignInClick}
          isRegistered={isRegistered}
        />
        <Footer />
      </div>
    </CurrentUserContext.Provider>
	);
};

export default App; */



