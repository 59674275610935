// import React from 'react';
// import './Header.css';

// export default function Header() {

//   return (
//     <header className="header">
//       <h2 className="header__title">NewsExplorer</h2>
//       <nav className="nav">
//         <button className="nav__button active">Home</button>
//         <button className="nav__button nav__button_displayed">Saved articles</button>
//         <button className="header__auth-link">Sign in</button>
//       </nav>
//     </header>

//   )
// }

// import React, { useState } from 'react';

// import Navbar from '../Navbar/Navbar';
// import { Route, Link, withRouter } from 'react-router-dom';

// function Header({ logout, userEmail }) {
//   const [mobile, setMobile] = useState(false)
//   const [toggleNavbar, setToggleNavbar] = useState(false)
//   const [buttonState, setButtonState] = useState(false)

//   const toggleMobile = () => {
//     setMobile(true);
//     setToggleNavbar(true);
//     setButtonState(true);
//   }

//   const toggleMenu = () => {
//     setMobile(false);
//     setToggleNavbar(false);
//     setButtonState(false);
//   }


//   return (
//     <div>
//     <div className={`header__mobile ${mobile ? 'header__mobile_open  ' : ''}`} >
//         <Route exact path="/">
//           <p className="header__user-display-info header__user-display-info_display">{userEmail}</p>
//           <button className="header__logout-button header__logout-button_display" onClick={logout}>Logout</button>
//         </Route>

//       </div>
//     <header className="header">

//       {/* <img src={logo} className="logo" alt="logo" /> */}

//       <Route exact path="/">
//           <button className={`header__close ${buttonState ? 'header__close_display' : ''}`} onClick={toggleMenu}></button>
//         <Navbar onClick={toggleMobile} isClicked={toggleNavbar} />
//         <p className="header__user-display-info">{userEmail}</p>
//         <button className="header__logout-button" onClick={logout}>Log out</button>
//       </Route>
//       <Route exact path="/signin"><p className="header__auth-info"> <Link to="/signup" className="header__auth-link">Sign up</Link></p></Route>
//       <Route exact path="/signup"><p className="header__auth-info"> <Link to="/signin" className="header__auth-link">Log in</Link></p></Route>


//     </header>
//     </div>
//   );
// }

// export default withRouter(Header);

import React, { useState, useEffect, createRef } from 'react';
import { NavLink, Route, Link, withRouter  } from 'react-router-dom';
import './Header.css';
import Login from '../Login/Login';
import Register from '../Register/Register';
import MobileNavigation from '../MobileNavigation/MobileNavigation';
import mobileMenuIconWhite from '../../images/mobile-menu-icon-white.svg';
import mobileMenuIconBlack from '../../images/mobile-menu-icon-black.svg';
import MenuCloseIcon from '../../images/menu-close-icon.svg';
import logoutIconWhite from '../../images/logout-icon-white.svg';
import logoutIconBlack from '../../images/logout-icon-black.svg';

export default function Header({
  loggedIn,
  currentUser,
  onSignInClick,
  // setIsItemListOpen,
  // setSearchKeyword,
  onSavedArticlesPage,
  onLogOut
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);  
  const [mobileMenu, setMobileMenu] = useState();
  // const [mobileMenuOverlay, setMobileMenuOverley] = useState(false);
  const [logoutIconDisplay, setLogoutIconDisplay] = useState();
  const [mobile, setMobile] = useState(false);
  let mobileRef = createRef();

  useEffect(() => {
    function checkSize() {
      const width = window.matchMedia('(max-width: 520px)');
      if (width.matches) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }
    checkSize();
    window.addEventListener('resize', checkSize);
    return () => window.removeEventListener('resize', checkSize);
  });
  
  useEffect(() => {
    if (!isMenuOpen && onSavedArticlesPage) {      
      setMobileMenu(mobileMenuIconBlack);
    } else if (!isMenuOpen && !onSavedArticlesPage) {
      setMobileMenu(mobileMenuIconWhite);
    } else if (isMenuOpen) {
      setMobileMenu(MenuCloseIcon);
    }
  }, [isMenuOpen, onSavedArticlesPage]);

  useEffect(() => {
    if (mobile) {
      setLogoutIconDisplay(logoutIconWhite);
    } else if (onSavedArticlesPage) {
      setLogoutIconDisplay(logoutIconBlack);
    } else if (!onSavedArticlesPage) {
      setLogoutIconDisplay(logoutIconWhite);
    }
  }, [mobile, onSavedArticlesPage]);

  useEffect((e) => {
    window.addEventListener('click', closeOnClickOutside);
    return () => window.removeEventListener('click', closeOnClickOutside);
  });

  function closeOnClickOutside(e) {
    if (mobileRef.current && !mobileRef.current.contains(e.target)) {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const closeByEscape = (e) => {
      if (e.key === 'Escape') {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('keydown', closeByEscape);
    return () => document.removeEventListener('keydown', closeByEscape);
  }, []);

  function onMobileMenuClick() {
    setIsMenuOpen(!isMenuOpen);
    // setMobileMenuOverley(true);
  }

  function handleNavClick() {      
    setIsMenuOpen(false);
    // setIsItemListOpen(false);
    // setSearchKeyword('');
  }

  function handleLogButtonClick() {
    onSignInClick();
    handleNavClick();   
  }

  function logOut() {
    handleNavClick();
    onLogOut();
  }

  return loggedIn ? (
    <header
      ref={mobileRef}
      className={`header ${
        isMenuOpen ? 'header_mobile-menu-open' : 'header_mobile-menu-closed'
      }`}
    >
      <NavLink
        className={`header__title ${
          onSavedArticlesPage && 'header__title_saved-articles'
          } ${isMenuOpen && 'header__title_menu-open'}`}
        exact
        to="/items"
        onClick={handleNavClick}
      >
        
      </NavLink>
      <img
        className="header__menu-icon"
        alt="menu icon"
        src={mobileMenu}
        onClick={onMobileMenuClick}
      />
      <nav
        className={`header__navigation ${
          mobile && isMenuOpen
            ? 'header__navigation_mobile-login'
            : 'header__navigation_mobile-inactive'
        }`}
      >
        <MobileNavigation mobile={mobile}>
          <NavLink
            className={`header__link-home ${
              onSavedArticlesPage && 'header_black'
            }`}
            activeClassName={
              onSavedArticlesPage
                ? 'header__active_black'
                : 'header__active_white'
            }
            exact
            to="/items"
            onClick={handleNavClick}
          >
            
          </NavLink>        
          <NavLink
            className={`header__button header__signout-button header__button_login ${
              onSavedArticlesPage && 'header__button_saved-articles'
            }`}
            to="/"
            onClick={logOut}
          >
            <p className="header__button-username">{currentUser.name}</p>
            <img
              className="header__button-logout-icon"
              src={logoutIconDisplay}
              alt="logout icon"
            />
          </NavLink>
        </MobileNavigation>
      </nav>
    </header>
  ) : (
    <header
      ref={mobileRef}
      className={`header ${
        isMenuOpen ? 'header_mobile-menu-open' : 'header_mobile-menu-closed'
        }`}
    >
      <NavLink className="header__title" exact to="/" onClick={handleNavClick}>
        Shopping list
      </NavLink>
      <img
        className="header__menu-icon"
        alt="menu icon"
        src={mobileMenu}
        onClick={onMobileMenuClick}
      />
      <div
        className={`header__navigation ${
          mobile && isMenuOpen
            ? 'header__navigation_mobile-logout'
            : 'header__navigation_mobile-inactive'
        }`}
      >
        <MobileNavigation mobile={mobile}>
          <NavLink
            className="header__link-home"
            activeClassName="header__active_white"
            exact
            to="/"
            onClick={handleNavClick}
          >            
          </NavLink>
          <NavLink
            className={
              'header__button header__signin-button header__button_logout'
            }
            to="/"
            onClick={handleLogButtonClick}
          >
            Sign In
          </NavLink>
        </MobileNavigation>        
      </div>
    </header>
  );
}
