class Api {
    constructor({ baseUrl }) {
        this._baseUrl = baseUrl;
    }

    _returnRes(res) {
      if (res.ok) {
        return res.json()
      } else {
        return Promise.reject(`Error ${res.status}`);
      }
    }

    getAppInfo() {
      return Promise.all([this.getItems(), this.getCurrentUser()])
    }

    getCurrentUser(token) {
      return fetch(this._baseUrl + '/users/me', {
        headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }).then((res) => {
        return this._returnRes(res);
      });
    }

    getItems(token, userId) {
      return fetch(this._baseUrl + '/items/user/' + userId, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,          
        },        
      })
      .then(this._returnRes)
    }
    
    setData(item, token) {
      return fetch(this._baseUrl + '/', {
        method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            item
          )
        })
          .then(this._returnRes)
    }

    removeItem(itemId, token) {
      return fetch(this._baseUrl + '/' + itemId, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'DELETE'
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to delete item');
        }
        // Optionally, you can return response.json() here if your backend sends back data upon successful deletion
        return response.json();
      });
    }

    selectedItem(id, token) {
      return fetch(this._baseUrl + '/select/' + id, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(this._returnRes)
    }
  
    unselectedItem(id, token) {
      return fetch(this._baseUrl + '/select/' + id, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(this._returnRes)
    }
}

// const api = new Api({
//   baseUrl: 'http://192.168.1.10:3000',
//   // headers: {
//   //   "Content-Type": "application/json"
//   // }
//  });

 const api = new Api({
  baseUrl: 'https://roche.kaltech.info',
  // headers: {
  //   "Content-Type": "application/json"
  // }
 });

// const api = new Api({
//   baseUrl: 'https://192.168.1.4:3000',
// });

export default api
